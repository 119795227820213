@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.static-content, .main-content {
	min-height: 300px;
	padding-bottom: 15px; }

.search-ico {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 10px; }

.hero-image-wrapper {
	height: 100vh;
	width: 100%;
	position: relative; }

.wrapper-subpage {
	height: 30vh !important; }

.hero-image {
	height: 100%;
	width: 100%;
	position: fixed;
	background-position: center center;
	background-size: cover;
	//&:after
	//ontent: ""
	//ackground-image: url("../images/svg/vlna_white_podheader.svg")
	//ackground-size: cover
	//idth: 100%
	//eight: 32px
	//isplay: block
	//osition: absolute
 }	//ottom: 0
.main-content {
	background-color: white;
	position: relative;
	&:before {
		content: "";
		background-image: url("../images/svg/vlna_white_podheader.svg");
		background-size: cover;
		width: 100%;
		height: 32px;
		display: block;
		position: absolute;
		top: -30px; } }
.nowave {
	&:before {
		background-image: none !important; } }

/* SLIDESHOW */

#slideshow {
	height: 250px !important;
	width: 100%;
	//display: block
	position: relative;
	li {
		height: 100%;
		> div {
			height: 100%;
			width: 100%;
			&:before {
				content: "";
				background-image: url("../images/svg/vlna_header_left.svg");
				background-position: center;
				background-size: 100% 100%;
				width: 20px;
				height: 100%;
				display: inline-block;
				position: absolute;
				top: 0;
				left: 10px; }
			&:after {
				content: "";
				background-image: url("../images/svg/vlna_header_right.svg");
				background-position: center;
				background-size: 100% 100%;
				width: 20px;
				height: 100%;
				display: inline-block;
				position: absolute;
				top: 0;
				right: 10px; } } } }

.slide-text {
	font-size: 40px;
	color: white;
	font-weight: 700;
	line-height: 45px;
	text-shadow: 4px 4px black;
	@media (max-width: 768px) {
		font-size: 30px;
		line-height: 40px; } }
.n-slider {
	top: 200px; }

.lSPager {
	position: fixed;
	bottom: 70px;
	left: 40%;
	right: 40%; }

.lSSlideOuter .lSPager.lSpg {
	> li {
		a {
			background-color: transparent;
			border: 2px solid white;
			width: 14px;
			height: 14px; } }
	> li.active {
		a {
			background-color: white; } }
	> li:hover {
		a {
			background-color: white; } } }

/* TYPY */

.type-wrapper {
	margin-top: 80px;
	margin-bottom: 80px;
	display: block;
	position: relative;
	@media (max-width: 991px) {
		margin-top: 20px;
		margin-bottom: 20px; } }
.type-heading {
	font-size: 25px;
	font-weight: 700;
	text-align: center; }
.type-icon {
	margin-bottom: 35px;
	height: 125px;
	width: 125px;
	display: block;
	margin-left: auto;
	margin-right: auto; }
.type-item {
	height: $vyskakarty;
	@media (max-width: 991px) {
		margin-bottom: 20px; } }


.type-heading-flip, .type-subheading-flip {
 }	//color: white
.type-heading-flip {
	font-weight: 700;
	font-size: 40px; }
.type-subheading-flip {
	font-weight: 700;
	font-size: 30px; }

/* FLIP ANIMACIA */

.flip-container {
	perspective: 1000px; }
.flip-container:hover .flipper, .flip-container.hover .flipper {
	transform: rotateY(180deg); }
.flip-container, .front, .back {
	width: 100%;
	height: $vyskakarty; }
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative; }
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0; }
.front {
	z-index: 2;
	transform: rotateY(0deg); }

.back {
	transform: rotateY(180deg);
	//img
	//webkit-transform: scaleX(-1)
 }	//ransform: scaleX(-1)

/* FLIP ANIMACIA V2 */
//.type-icon
//ransition: 0.6s
//ransform-style: preserve-3d
//osition: relative
//.flip-container:hover .type-icon, .flip-container.hover .type-icon
//ransform: rotateY(180deg)

/* KTO SME */

.kto-wrapper {
	display: block;
	position: relative;
	padding-top: 80px;
	padding-bottom: 80px; }


.kto-text {
	line-height: 25px;
	ul {
		padding-left: 0px;
		list-style: none;
		display: table;
		li {
			list-style: none;
			display: table-row;
			&:before {
				padding-right: 10px;
				display: table-cell;
				text-align: right;
				content: "• ";
				color: $blue; } } } }

/* PREDAJ - voda ... */
.predaj-item-wrap {
	height: 650px;
	width: 100%;
	position: relative;
	overflow: hidden;
	@media (max-width: 991px) {
		height: 480px; }
	&:nth-child(even) {
		.predaj-item {
			&:before {
				background-color: $filter; }
			//&:hover
			//:before
 } }			//background-color: $filter-hover
	&:nth-child(odd) {
		.predaj-item {
			span, h2, a {
				color: black; }
			a {
				border: 2px solid black; }
			&:before {
				right: 0;
				background-color: $filter2; }
			//&:hover
			//:before
 } }			//background-color: $filter-hover2
	.predaj-item {
		//height: 780px
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: $transition;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 50%;
			height: 100%;
			transition: $transition;
			@media (max-width: 991px) {
				width: 100%; } }
		&:hover {
			transform: scale(1.05);
			transition: $transition;
			&:before {
				transition: $transition; } }
		span {
			margin-top: 20px;
			color: white;
			line-height: 30px;
			font-size: 16px;
			display: block; } } }

/* PROJEKTY */

#projekty {
	padding-left: 0; }

.projekty {
	height: 815px;
	width: 100%;
	display: block;
	position: relative;
	background-image: url('../images/projekty.png');
	background-position: center;
	background-size: cover;
	h2 {
		margin-top: 75px;
		margin-bottom: 40px; }
	li {
		&:nth-child(even) {
			background-color: $projektbg2; }
		&:nth-child(odd) {
			background-color: $projektbg1; } } }

.projekt-item {
	height: 500px !important;
	position: relative;
	display: inline-block;
	span {
		display: block; }
	.projekt-name {
		padding-top: 50px;
		padding-bottom: 20px;
		color: $darkgray;
		font-size: 30px;
		font-weight: 700;
		text-align: center;
		line-height: 35px;
		height: 140px;
		overflow: hidden; }
	.projekt-text {
		height: 100px;
		overflow: hidden;
		text-align: center; }
	.projekt-btn {
		margin: 20px auto;
		width: 160px;
		padding-top: 25px;
		padding-bottom: 25px;
		text-align: center;
		font-size: 13px;
		font-family: $font;
		font-weight: 700;
		border-radius: 50px;
		text-transform: uppercase;
		display: block;
		background-color: transparent;
		border: 2px solid $gray;
		color: $gray;
		transition: $transition;
		&:hover, &:active, &:focus {
			background-color: $blue;
			color: white;
			text-decoration: none;
			transition: $transition;
			border: 2px solid $blue; } }
	&:hover, &:active, &:focus {
		.projekt-btn {
			background-color: $blue;
			color: white;
			text-decoration: none;
			transition: $transition;
			border: 2px solid $blue; }
		.projekt-name {
			color: $blue; } } }
.projekt-image {
	margin-top: 15px;
	height: 220px;
	width: 100%;
	display: block;
	position: relative;
	background-size: cover;
	background-position: center; }

/* KONFERENCIA UPDATE 14.3.2019 */

.konferencia-wrapper {
	display: inline-block;
	margin: 100px auto 0px auto;
	padding: 1rem 4rem;
	border-radius: 15px;
	background-color: #ffae00;
	@media (max-width: 768px) {
		padding: 1rem 2rem; }
	@media (max-width: 420px) {
		margin: 80px auto 0 auto; }
	p {
		margin-bottom: 0px; }
	.text-1 {
		font-size: 1.2rem;
		color: white; }
	.text-2, .text-3 {
		color: #011b29; }
	.text-2 {
		font-weight: 700;
		font-size: 1.8rem;
		text-transform: uppercase;
		@media (max-width: 768px) {
			font-size: 1.4rem; } }
	.text-3 {
		font-size: 1.1rem;
		margin-top: -5px; }
	.text-circle {
		width: 125px;
		height: 125px;
		background-color: #fff;
		display: inline-block;
		border-radius: 50%;
		color: #011b29;
		padding: 2.5rem 1rem;
		font-size: 1.2rem;
		position: absolute;
		right: 70px;
		top: 157px;
		b {
			display: block;
			line-height: 1rem; }
		@media (max-width: 1199px) {
			right: 80px; }
		@media (max-width: 991px) {
			right: 50px; }
		@media (max-width: 768px) {
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			top: 240px; } }


	/* BLOG */ }

.blog_class {
	.col-lg-12 {
		padding-left: 0; }
	.projekt-name {
		text-align: left;
		padding-bottom: 0;
		height: 95px; }
	.projekt-text {
		text-align: left; }
	.projekt-btn {
		margin-top: 0;
		margin-bottom: 0; } }
