@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.prnts {
	display: flex;
	@media (max-width: 991px) {
		display: block; } }
.main-category-bg {
	background-color: $graybg;
	padding-bottom: 35px;
	@media (max-width: 991px) {
		h2 {
			&:before {
				margin: 10px auto; } } } }
.main-category {
	flex: auto;
	flex: -moz-available;
	display: contents;
	@media (max-width: 991px) {
		width: 100%;
		img {
			display: none; } } }

.category-heading {
	padding-top: 35px;
	@media (max-width: 991px) {
		padding-top: 15px;
		text-align: center; } }
.main-category-item {
	height: 220px;
	background-color: white;
	border: 1px solid $category-border;
	overflow: hidden;
	&:hover, &:focus, &:active {
		border: 1px solid $category-border-hover; }
	@media (max-width: 991px) {
		height: auto;
		width: 100%; } }
.category-icon {
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	top: 20px;
	right: 10px;
	z-index: 99; }

.category-image {
	width: auto;
	display: block;
	margin: 0 auto;
	//height: 150px
	height: 10vh;
	margin-top: 25px;
	margin-bottom: 25px; }

.category-name {
	display: block;
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	color: $menufontcolor;
	@media (max-width: 991px) {
		margin-top: 10px;
		margin-bottom: 10px; }
	@media (max-width: 500px) {
		font-size: 12px; } }
.product-row {
	background-color: $graybg;
	margin-bottom: 10px !important; }
.product {
	background-image: url(../images/svg/vlna_header_left.svg);
	background-repeat: no-repeat;
	background-position: 0 center;
	padding-left: 35px;
	@media (max-width: 991px) {
		background-image: none;
		padding-bottom: 10px; }
	//&:before
	//ontent: ""
	//eight: 100%
	//idth: 8px
	//ackground-image: url(../images/svg/vlna_header_left.svg)
	//ackground-size: cover
 }	//ackground-position: center
.product-wrap {
	height: 215px;
	margin-top: 15px;
	margin-bottom: 15px;
	background-color: white;
	border: 1px solid $category-border;
	color: $productfontcolor;
	&:hover, &:active, &:focus {
		border: 1px solid $category-border-hover; }
	img {
		max-height: 210px;
		height: auto;
		display: block;
		margin: 0 auto; }
	@media (max-width: 991px) {
		height: auto; } }
.productname {
	height: 66px;
	overflow: hidden;
	font-weight: 700;
	font-size: 22px;
	color: $productfontcolor;
	padding-right: 35px; }
.catalog-product-detail {
	position: absolute;
	right: -5px;
	bottom: -20px;
	padding: 20px 20px;
	background-color: $blue;
	color: white;
	border: 2px solid $blue;
	transition: $transition;
	border-radius: 50px;
	font-weight: 600;
	text-transform: uppercase;
	display: block;
	text-align: center;
	&:hover, &:active, &:focus {
		color: $blue;
		border: 2px solid $blue;
		background-color: white;
		transition: $transition; } }
.productshort {
	font-size: 17px;
	color: $productfontcolor; }
.subtext {
	padding-bottom: 20px; }
.simple-filter {
	border-radius: 50px;
	border: 1px solid $category-border;
	display: inline-block;
	padding: 10px;
	select {
		border: 1px solid transparent;
		box-shadow: none;
		&:focus {
			border-color: transparent;
			box-shadow: none; } } }

/* PRODUCT DETAIL */

.product-detail {
	background-color: $graybg;
	padding-top: 15px;
	padding-bottom: 15px; }

.product-detail-main {
	border: 1px solid $category-border;
	background-color: white; }

.product-detail-info {
	background-image: url(../images/svg/vlna_header_left.svg);
	background-repeat: no-repeat;
	background-position: 0 center;
	background-size: auto 80%;
	padding-top: 10px;
	padding-left: 35px;
	@media (max-width: 991px) {
		background-image: none; } }

.skladom {
	font-weight: 700;
	color: $blue; }

.product-detail-info {
	p {
		margin-bottom: 5px; } }

.product-detail-price-row {
	color: $menufontshover; }

.product-detail-price-main-row {
	font-weight: 700; }

.main-price {
	color: $blue;
	font-weight: 700; }
.product-detail-input {
	width: auto; }
.btn-offset {
	margin-top: 10px;
	margin-bottom: 10px; }

.tabs-wrapper {
	padding-bottom: 25px; }

.nav-tabs {
	border-bottom: 1px solid transparent; }

.tab-content {
	border: 1px solid $category-border;
	margin-top: -1px; }

.product-detail-tabs {
	> li {
		> a {
			border: 1px solid $category-border;
			background-color: white;
			font-size: 16px;
			font-weight: 700; } } }

.product-detail-tabs {
	li, a {
		color: #0a0a0a; } }

.product-detail-tabs {
	> li {
		> .active {
			border-bottom: 1px solid transparent; } } }

.tab-pane {
	> div, > span {
		padding: 15px; } }

.tab-pane {
	> span {
		> h3 {
			padding-left: 15px; } } }
.product-detail-description {
	li {
		padding-top: 5px; } }
