@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Kanit:200,300,400,500,700&subset=latin-ext');

html, body, a, p, span, b, div, ul, li, h1 h2, h3, h4, h5, h6 {
	font-family: $font; }

a {
	&:hover, &:focus, &:active {
		text-decoration: none; } }
.link-active {
	pointer-events: auto !important; }
.linkblue {
	a {
		color: $blue;
		&:hover, &:active, &:focus {
			color: $darkblue; } } }

.main-heading, .h2inside h2 {
	font-size: 35px;
	font-weight: 700;
	&:before {
		content: "";
		background-image: url("../images/svg/wave_nadpisy.svg");
		height: 10px;
		width: 96px;
		display: block;
		margin-bottom: 25px; } }
.main-heading.text-center {
	&:before {
		margin-left: auto;
		margin-right: auto; } }
.f-silver {
	color: $silver; }
.f-blue {
	color: $blue;
	&:hover, &:active, &:focus {
		color: $blue; } }
.f-dark {
	color: #555555; }
.f-white {
	color: #ffffff;
	&:hover {
		color: $blue; } }
.bg-blue {
	background-color: $blue;
	&:hover {
		background-color: white; } }
.bg-white {
	background-color: white;
	&:hover {
		background-color: $blue; } }
.bg-gray {
	background-color: $graybg; }
.bg-transparent {
	background-color: transparent;
	border: 1px solid white; }
.white {
	color: white; }
.gray {
	color: $darkgray; }
.font-right {
	text-align: right;
	h2 {
		&:before {
			margin-left: auto; } }
	.transparent-btn {
		float: right; } }
.nopointer {
	pointer-events: none; }

.main-btn {
	width: 160px;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
	font-size: 14px;
	font-family: $font;
	font-weight: 700;
	border-radius: 50px;
	text-transform: uppercase;
	display: block; }

.transparent-btn {
	width: 160px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
	font-size: 13px;
	font-family: $font;
	font-weight: 700;
	border-radius: 50px;
	text-transform: uppercase;
	display: inline-block;
	background-color: transparent;
	border: 2px solid white;
	color: white;
	transition: $transition;
	&:hover, &:active, &:focus {
		background-color: $blue;
		color: white !important;
		text-decoration: none;
		transition: $transition;
		border: 2px solid $blue !important; } }

.base-btn {
	padding: 25px 50px;
	border-radius: 50px;
	font-weight: 700;
	text-transform: uppercase;
	position: relative;
	top: 40px;
	background-color: white;
	transition: $transition;
	color: black;
	&:hover, &:active, &:focus {
		background-color: $blue;
		color: white;
		transition: $transition; } }

.center-btn {
	margin-left: auto;
	margin-right: auto; }

.img-center {
	margin-left: auto;
	margin-right: auto;
	display: block; }

/* SLIDER CONTROLS - LIGHTSLIDER */
.lSAction > a {
	background-image: none;
	opacity: 1; }
.lSAction > .lSPrev {
	left: 0px; }
.lSAction > .lSNext {
	right: 0px; }

/* SLICK SLIDER */

.prev-control {
	&:after {
		content: "";
		background-image: url("../images/svg/left_icon_big.svg");
		width: 32px;
		height: 32px;
		display: block;
		cursor: pointer; }
	&:hover {
		&:after {
			background-image: url("../images/svg/left_onover_icon_big.svg"); } } }
.next-control {
	&:after {
		content: "";
		background-image: url("../images/svg/right_icon_big.svg");
		width: 32px;
		height: 32px;
		display: block;
		cursor: pointer; }
	&:hover {
		&:after {
			background-image: url("../images/svg/right_onover_icon_big.svg"); } } }


.controlslick-left, .controlslick-right {
	cursor: pointer;
	z-index: 10; }

.controlslick-left {
	display: block;
	position: absolute;
	left: -80px;
	top: 50%;
	@media (max-width: 991px) {
		left: 0; } }
.controlslick-right {
	display: block;
	position: absolute;
	right: -80px;
	top: 50%;
	@media (max-width: 991px) {
		right: 0; } }

.double {
	&:after {
		width: 60px;
		height: 60px; }
	@media (max-width: 991px) {
		&:after {
			width: 30px;
			height: 30px; } } }

.nopad {
	padding: 0 !important;
	margin: 0 !important; }

.nowaveinside {
	> h2 {
		&:before {
			background-image: none; } } }

/* FLEX */

.customflex {
	display: flex;
	flex-wrap: wrap; }
.customflex-item {
	align-self: center; }

/* FLOATS */
.float-r {
	float: right; }

/* MOBILE */

//.mobile-text-center
//ext-align: center
.mobile-image-center {
	@media (max-width: 991px) {
		display: block;
		margin: 0 auto; } }

@media (max-width: 991px) {
	.img-ktosme {
		margin-top: 25px;
		max-height: 250px; } }


/* MENU BLOCK - DOCASNY */

.leftmenu {
	li {
		pointer-events: none; }
	.has-menu {
		pointer-events: auto !important; } }
.main-category {
	div {
		a {
			pointer-events: none; } } }

@import 'master';

@import 'footer';
@import 'sixadmin';

@import 'modules/article';
@import 'modules/menu';
@import 'modules/contact';
@import 'modules/home';
@import 'modules/partneri';

@import 'modules/catalog';
@import 'modules/footer';

@import 'default_javascript';
