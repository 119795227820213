$primaryColor: #005eb2;
$secondaryColor: #05ca00;
$tertiaryColor: #ffffff;
$quaternaryColor: red;
$pentiaryColor: green;

$gray: #c0c0c0;
$silver: #cccccc;
$darkgray: #555555;
$placeholder: #9e9e9e;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;

$gutter: 15px;

/* ASUAN VARIABLES */

$font: 'Poppins', sans-serif;
$font2: 'Kanit', sans-serif;

$blue: #32a3dc;

$darkblue: #011b29;
$footer_font_color: #ffffff;
$footer_href_color: #32a3dc;

$menufontcolor: #2b2b2b;
$menufontshover: #878787;

$vyskakarty: 230px;

$projektbg1: #ffffff;
$projektbg2: #f2f2f2;

$filtercolor: #011b29;
$filteropacity: 0.75;

$filter: rgba(1, 27, 41, 0.75);
$filter-hover: rgba(1, 27, 41, 0.5);
$filter2: rgba(255, 255, 255, 0.75);
$filter-hover2: rgba(255, 255, 255, 0.5);

$category-border: #ebebeb;
$category-border-hover: #a1a1a1;

$productfontcolor: #2b2b2b;

$graybg: #f7f7f7;

$silver: #9a9a9a;

$transition: 0.2s all;

$menu_base: 150px;
$menu_base2: 140px;
$menu_base3: 110px;

$menu_base_scroll: 75px;
$menu_base2_scroll: 70px;
$menu_base3_scroll: 55px;
