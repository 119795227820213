@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.detail, .static-content {
	table {
		margin: 30px auto;
		display: table;
		tr {
			td {
				padding: 8px 25px;
				border: 1px solid #f2f2f2;
				&:first-child {
					font-weight: 700; } }
			&:nth-child(2n) {
				background-color: #f2f2f2; } } }
	ul {
		padding-top: 10px;
		padding-bottom: 25px;
		padding-left: 15px;
		list-style: none;
		li {
			padding-top: 4px;
			padding-bottom: 4px;
			&:before {
				content: "\f111";
				font-family: "FontAwesome";
				color: #35a2db;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
				padding-right: 20px;
				font-size: 12px; } } } }
.detail-gallery {
	padding-bottom: 25px;
	padding-top: 15px;
	.item {
		display: block;
		margin: 5px auto;
		height: 200px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat; } }

