@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.kontakt {
	padding-top: 90px;
	padding-bottom: 95px;
	.mapa {
		iframe {
			height: 580px;
			width: 100%;
			display: block;
			position: relative; }
		@media (max-width: 991px) {
			iframe {
				height: 220px; } } }
	.formular {
		.kontakt-info {
			font-family: $font2;
			font-weight: 300;
			margin-bottom: 35px;
			display: block; } } }

.form-input {
	font-family: $font2;
	width: 100%;
	display: block;
	background-color: transparent;
	padding: 25px 30px;
	border-radius: 15px;
	border: 1px solid $silver;
	color: $placeholder;
	margin-bottom: 10px; }
.form-textarea {
	height: 170px;
	resize: none; }
.contact-lists {
	font-size: 20px;
	font-family: $font2;
	p, ul, li, a, strong {
		font-family: $font2 !important; }
	p {
		font-family: $font2;
		font-weight: 300; }
	ul {
		list-style: none;
		padding-left: 0px;
		padding-top: 15px;
		padding-bottom: 15px;
		> li {
			&:first-child {
				color: $blue;
				padding-bottom: 20px;
				font-weight: 700; } }
		li {
			font-size: 20px;
			font-weight: 300; }
		a {
			color: black; }
		strong {
			font-weight: 700 !important; } } }
.form-part {
	background-color: $graybg;
	margin-top: 50px;
	padding-top: 25px;
	margin-bottom: 25px; }
.white-bg {
	background-color: white !important; }
.asuanmap {
	width: 100%;
	height: 495px; }
