.navbar-default {
	//background-color: transparent
	background-color: $darkblue;
	border-color: transparent;
	min-height: $menu_base;
	height: auto;
	width: 100%;
	position: fixed;
	z-index: 999;
	transition: 0.4s all;
	border-radius: 0px;
	a {
		color: white !important;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 400; } }

.navbar-default.scroll {
	min-height: $menu_base_scroll !important;
	.navbar-header {
		height: $menu_base2_scroll !important; }
	.navbar-logo {
		max-height: $menu_base3_scroll !important; } }

.navbar-scroll {
	background-color: $darkblue;
	transition: 0.4s all;
	//box-shadow: 0 0 10px rgba(0,0,0,.5)
 }	//box-shadow: 0 0 15px -1px #000
.navbar-nav {
	//float: right
	> li {
		top: -2px;
		> a {
			&:before {
				content: "";
				background-image: none;
				width: 100%;
				height: 5px;
				display: block;
				position: relative;
				top: -5px;
				transition: 0.5s all;
				background-repeat-y: no-repeat;
				background-size: contain; }
			&:hover, &:active, &:focus {
				&:before {
					background-image: url(../images/svg/wave_nadpisy.svg);
					transition: 0.5s all; } } } }
	> .active {
		> a {
			background-color: transparent !important;
			&:before {
				background-image: url(../images/svg/wave_nadpisy.svg); } } }
	.menu_level_1 {
		height: 0px;
		overflow: hidden;
		position: absolute;
		background-color: transparent;
		z-index: 9999;
		color: #ffffff;
		padding-left: 0em;
		transition: $transition;
		list-style: none;
		text-align: left;
		margin-left: 15px;
		li {
			margin-top: 20px;
			//a
			//:before
			//content: ""
			//background-image: none
			//width: 100%
			//height: 5px
			//display: block
			//position: relative
			//top: -5px
			//:hover, &:active, &:focus
			//&:before
 }			//	background-image: url(../images/svg/wave_nadpisy.svg)
		> li {
			> ul {
				display: none; } } } }

.navbar-header {
	width: 100%;
	height: $menu_base2; }
.navbar-logo {
	max-height: $menu_base3;
	height: auto;
 }	//float: right

.navbar-nav li:hover .menu_level_1 {
	transition: $transition;
	height: auto; }
.menu_level_1:hover {
	transition: $transition;
	height: auto; }
//.menu_level_1 a
//olor: #777
//.menu_level_1
//isplay: none

.noflex-logo {
	top: 50px; }
.noflex-menu {
	//top: 50px
	height: 100%; }
.noflex-button {
	top: 0px; }

@media (max-width: 991px) {
	.navbar-default .navbar-toggle .icon-bar {
		background-color: $blue; }
	.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
		background-color: white; }
	.search-ico {
		margin-top: 10px;
		margin-bottom: 10px; }
	.noflex-menu {
		top: 0;
		margin-top: 5px;
		margin-bottom: 0px; }
	.navbar-default {
		min-height: 120px; }
	.navbar-header {
		height: 120px; }
	.navbar-collapse {
		box-shadow: none;
		border: 1px solid transparent;
		border-color: transparent !important;
		width: 100%;
		background-color: $darkblue;
		padding-top: 15px;
		padding-bottom: 15px !important; }
	.navbar-toggle {
		display: block;
		position: absolute;
		right: 0;
		z-index: 10; }
	.navbar-collapse.collapse {
		display: none !important; }
	.navbar-collapse.collapse.in {
		display: block !important; }
	.menu_level_1 {
		display: none; }
	.navbar-nav {
		margin: 0 auto;
		float: none;
		display: inline-block;
		> li {
			> a {
				&:before {
					width: 50%;
					margin: 0 auto; } } } }
	.navbar-default {
		a {
			display: block;
			margin: 0 auto; } } }
/* LAVE MENU ESHOP */
.leftmenu {
	background-color: $graybg;
	li {
		ul {
			display: none; } }
	li.active {
		> ul {
			display: block; } } }

.left-menu-heading {
	background-color: $darkblue;
	color: white;
	font-size: 35px;
	font-weight: 700;
	padding: 80px 0px 40px 50px;
	margin-bottom: 0px;
	@media (max-width: 991px) {
		padding: 20px 0 20px 30px; } }
.frst {
	padding-left: 0px;
	padding-top: 30px;
	ul {
		list-style: none; }
	a {
		color: $menufontcolor;
		&:hover, &:active, &:focus {
			color: $blue; } }
	li {
		padding-top: 4px;
		padding-bottom: 4px; }
	> li {
		padding-left: 20px;
		&:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4) {
			&:before {
				content: "";
				background-size: cover;
				background-position: center;
				width: 24px;
				height: 24px;
				display: inline-block;
				right: 5px;
				position: relative; } }
		&:nth-child(1) {
			&:before {
				background-image: url(../images/svg/voda_piktogram.svg); } }
		&:nth-child(2) {
			&:before {
				background-image: url(../images/svg/kanal_piktogram.svg); } }
		&:nth-child(3) {
			&:before {
				background-image: url(../images/svg/priemysel_piktogram.svg); } }
		&:nth-child(4) {
			&:before {
				background-image: url(../images/svg/odvodnenie_piktogram.svg); } }
		> a {
			font-weight: 700;
			font-size: 25px;
			color: $menufontcolor; }
		> ul {
			padding-left: 40px;
			padding-right: 10px;
			> li {
				> a {
					font-size: 18px; }
				ul {
					padding-left: 20px;
					li {
						a {
							color: $menufontshover;
							font-weight: 300;
							font-size: 12px; } } } } }
		.active {
			> a {
				color: $blue; } } }
	@media (max-width: 991px) {
		padding-top: 10px;
		padding-bottom: 10px; } }

