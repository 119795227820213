#partneri {
	padding-left: 0; }
.partneri {
	padding-top: 75px;
	height: 435px;
	width: 100%;
	display: block;
	position: relative;
	background-color: $darkblue;
	@media (max-width: 991px) {
		padding-top: 25px;
		height: auto;
		padding-bottom: 55px; } }
.partneri-wrapper {
	padding-top: 10px; }
