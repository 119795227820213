.footer {
	background-color: $darkblue;
	width: 100%;
	min-height: 370px;
	height: auto;
	position: relative;
	display: block;
	color: white;
	a {
		color: white;
		&:hover {
			color: $footer_href_color;
			text-decoration: none; } }
	.footer-info {
		text-align: center;
		padding-bottom: 50px; }
	.footer-content {
		font-size: 11px; } }
.footer-logo {
	margin-top: 40px;
	max-height: 190px;
	@media (max-width: 991px) {
		margin: 30px auto 0px auto; } }
.footer-second-part {
	margin-top: 80px; }

.footer-heading {
	padding-top: 45px;
	text-transform: uppercase;
	font-weight: 700; }

.footer-nav {
	list-style: none;
	padding-left: 0;
	line-height: 30px;
	font-size: 12px; }

.social-icon {
	&:after {
		width: 32px;
		height: 32px;
		content: "";
		display: inline-block;
		position: relative;
		margin-right: 20px; } }
.footer-second-part {
	.social-icon {
		&:last-child {
			&:after {
				margin-right: 0px; } } } }
.social-fb {
	&:after {
		background-image: url("../images/svg/facebook_icon_white.svg"); }
	&:hover {
		&:after {
			background-image: url("../images/svg/facebook_icon_onover.svg"); } } }
.social-gplus {
	&:after {
		background-image: url("../images/svg/gplus_icon_white.svg"); }
	&:hover {
		&:after {
			background-image: url("../images/svg/gplus_icon_onover.svg"); } } }
.social-insta {
	&:after {
		background-image: url("../images/svg/insta_icon_white.svg"); }
	&:hover {
		&:after {
			background-image: url("../images/svg/insta_icon_onover.svg"); } } }
